import  { useEffect } from 'react';
import { loadModules } from '@esri/react-arcgis';

const Track = (props) => {
    useEffect(() => {
        loadModules(["esri/widgets/Track"]).then(([Track])=>{
            const track = new Track({
                view: props.view
            });
            props.view.ui.add(track, "top-left");
        }).catch((err) => console.error(err));
    }, [props]);
    return null;
}

export default Track;