import  { useEffect } from 'react';
import { loadModules } from '@esri/react-arcgis';

const LayerList = (props) => {
    useEffect(() => {
        loadModules(["esri/widgets/LayerList","esri/widgets/Expand"]).then(([LayerList, Expand])=>{
            props.view.when(function() {
                var layerList = new Expand({
                    content: new LayerList({
                        view: props.view,
                        listItemCreatedFunction: function(event) {
                            const item = event.item;
                            if (item.layer.type !== "group") {
                              // don't show legend twice
                                item.panel = {
                                    content: "legend",
                                    open: true
                                };
                            }
                        }
                    }),
                    view: props.view,
                    expanded: false
                });
                props.view.ui.add(layerList, "top-right");
            });
        }).catch((err) => console.error(err));
    }, [props]);
    return null;
}

export default LayerList;