import React from 'react';
import { Map } from '@esri/react-arcgis';
import Search from './ArcGISSearch';
import Locate from './ArcGISLocate';
import FeatureLayer from './ArcGISFeatureLayer';
import Basemap from './ArcGISBasemap';
import axios from "axios";
import Track from './ArcGISTrack';
import LayerList from './ArcGISLayerList';


class OCVArcGIS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appID: this.props.match.params.id,
            arcData: null,
            featureLayers: []
        }
    }
    async componentDidMount() {
        let arcData = await axios.get('https://cdn.myocv.com/ocvapps/'+ this.state.appID +'/public/int_arcgis.json');
        this.setState({
            arcData: arcData.data,
            featureLayers: arcData.data['data']['featureLayers']
        });
    }
    render(){
        return (
            <div style={{ width: '100vw', height: '100vh' }}>
                <Map
                    viewProperties={{
                        center: (this.state.arcData !== null) ? this.state.arcData.data.center : [0, 0],
                        zoom: (this.state.arcData !== null) ? this.state.arcData.data.zoom : "",
                        extent : (this.state.arcData !== null) ? this.state.arcData.data.extent : "",
                        popup: {
                            dockEnabled: true,
                            dockOptions: {
                                buttonEnabled: false,
                                position: "bottom-right"
                            }
                        }
                    }}
                >
                    <Locate/>
                    <Track/>
                    <Search/>
                    <LayerList/>
                    <Basemap/>
                    {
                        (this.state.arcData !== null && this.state.featureLayers.length !== 0) ? this.state.featureLayers.map((item, index) => {
                            return <FeatureLayer key={index} featureLayerProperties={item}/>
                        }) : ""
                    }
                </Map>
            </div>
        );
    }
}

export default OCVArcGIS;
