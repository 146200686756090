import  { useEffect } from 'react';
import { loadModules } from '@esri/react-arcgis';

const Locate = (props) => {
    useEffect(() => {
        loadModules(["esri/widgets/Locate"]).then(([Locate])=>{
            const locate = new Locate({
                view: props.view
            });
            props.view.ui.add(locate, "top-left");
        }).catch((err) => console.error(err));
    }, [props]);
    return null;
}

export default Locate;