import  { useEffect } from 'react';
import { loadModules } from '@esri/react-arcgis';

const Basemap = (props) => {
    useEffect(() => {
        loadModules(["esri/widgets/Expand","esri/widgets/BasemapGallery"]).then(([Expand, BasemapGallery])=>{
            const search = new Expand({
                content: new BasemapGallery({
                    view: props.view
                }),
                view: props.view,
                expanded: false
            });
            props.view.ui.add(search, "top-right");
        }).catch((err) => console.error(err));
    }, [props]);
    return null;
}

export default Basemap;