import  { useEffect } from 'react';
import { loadModules } from '@esri/react-arcgis';

const FeatureLayer = (props) => {
    useEffect(() => {
        loadModules(['esri/layers/FeatureLayer']).then(([FeatureLayer]) => {
            const myFeatureLayer = new FeatureLayer(props.featureLayerProperties);
            props.map.add(myFeatureLayer);
        }).catch((err) => console.error(err));
    }, [ props ]);

    return null;
}

export default FeatureLayer;